// import logo from './logo.svg';
import './styles/App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="comingSoon">
        <p>
           Das Leben ist zu kurz für langweilige Wanderungen...
        </p>
        <p>
          Harzer Stempeljagd
        </p>
        <p>
         Coming soon...
        </p>
        </div>
      </header>
    </div>
  );
}

export default App;
